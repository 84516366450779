import { render, staticRenderFns } from "./MGMPath.vue?vue&type=template&id=3fe7d9aa&scoped=true&"
import script from "./MGMPath.vue?vue&type=script&lang=js&"
export * from "./MGMPath.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe7d9aa",
  null
  
)

export default component.exports